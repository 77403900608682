<template>
  <div class="qr-code-image">
    <vue-qr :text="text"
      :size="size"
      :logoSrc="logoSrc"
      :logoScale="logoScale"
    />
  </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
  name: 'GuestWebQRCode',
  components: {
    VueQr,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 300,
    },
    logoSrc: {
      type: String,
      required: false,
      default: null,
    },
    logoScale: {
      type: Number,
      required: false,
      default: .2,
    },
  },
};
</script>

<style lang="scss">
  .qr-code-image {
    img {
      width: 7.5rem;
      height: 7.5rem;
    }
  }
</style>

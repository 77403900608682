export const checkInLink = (propertyPin, reservationPin, withParams, utmSource) => {
  const link = `${process.env.VUE_APP_GUEST_WEB_URL}/${propertyPin}/checkin-add?pin=${reservationPin}`;
  const utmSourceUsed = utmSource ? utmSource : 'checkin_qr_code';
  return link + (withParams ? `&utm_medium=kiosk&utm_source=${utmSourceUsed}` : '');
};

export const guestWebLink = (propertyPin, reservationPin, withParams, utmSource) => {
  const pinParam = reservationPin ? `?pin=${reservationPin}` : '';
  const link = `${process.env.VUE_APP_GUEST_WEB_URL}/${propertyPin}${pinParam}`;
  const connectSign = pinParam === '' ? '?' : '&';
  const utmSourceUsed = utmSource ? utmSource : 'checkin_qr_code';
  return link + (withParams ? `${connectSign}utm_medium=kiosk&utm_source=${utmSourceUsed}` : '');
};
